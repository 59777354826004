<script lang="ts">
	// max-width in px the popup should use
	export let maxWidth: string = '';

	let maxWidthStyle: string;
	let positionStyle: string;
	let displayMessage: string;
	let visible = false;

	function movePopupToUpperRightScreen(marginTop: number, marginRight: number) {
		positionStyle = `top: ${marginTop}px; right: ${marginRight}px; ${maxWidthStyle}`;
	}

	function movePopupBelowElement(element: HTMLElement, marginY: number = 8) {
		const clientRect = element.getBoundingClientRect();
		positionStyle = `top: ${clientRect.bottom + marginY + 1}px; left: ${clientRect.left}px;`;
	}

	export function showMessage(message: string) {
		movePopupToUpperRightScreen(116, 5);
		displayMessage = message;
		visible = true;
	}

	export function showMessageUnderElement(message: string, element: HTMLElement) {
		movePopupBelowElement(element);
		displayMessage = message;
		visible = true;
	}

	export function hideMessage() {
		visible = false;
	}

	$: maxWidthStyle = maxWidth ? `max-width: ${maxWidth}px;` : '';
</script>

{#if visible}
	<div
		style="{positionStyle} {maxWidthStyle}"
		class="fixed flex flex-row p-4 gap-1 z-50 bg-item-red-10 border border-item-red rounded-4"
	>
		<i class="size-[24px] icon-warning icon" />
		<p class="text-item-grey-60">{displayMessage}</p>
	</div>
{/if}
